// export const BASE_URL = "http://localhost:4000";
// export const BASE_URL = "http://172.17.0.153:4000";
// export const BASE_URL = "https://webnew.convobird.com";
// export const BASE_URL = "https://web.convobird.com";
// export const BASE_URL_SOCKET = "https://agent.convobird.com";
export const BASE_URL_SOCKET = "https://agentservices.convobird.com";
// export const BASE_URL_SOCKET = "http://172.17.0.153:8001";
// export const BASE_URL_SOCKET = "http://localhost:8001";
// export const BASE_URL = "http://localhost:8000";
// export const BASE_URL = "http://172.17.0.153:8000";
export const BASE_URL = "https://apigateway.convobird.com";
// export const BASE_URL = "http://172.17.0.153:8000";
// export const BASE_URL = "https://web.convobird.com";

