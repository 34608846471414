import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from '../../helpers/apicalls/constants/constants';
import { SignUp_Action } from '../../redux/Auth/AuthActions';

const CheckoutForm = ({ CurrentData, userData }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = async (event) => {
        event.preventDefault();
        setloading(true);
        if (elements == null) {
            return;
        }
        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            // Show error to your customer
            setErrorMessage(submitError.message);
            return;
        }
        // Create the PaymentIntent and obtain clientSecret from your server endpoint
        const res = await fetch(`${BASE_URL}/agents/user/stripe-payment`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ amount: CurrentData?.price * 100 })
        });
        const { data, status, error } = await res.json();
        console.log(data, "data")
        if (status) {
            // let newData = { ...userData?.data, packageId: CurrentData?._id };
            // dispatch(SignUp_Action(newData, userData?.rememberMe));
            setloading(false);
        }
        else {
            setloading(false);
            setErrorMessage(error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button style={{ backgroundColor: "#355e64", color: "#fff", marginTop: 10, height: 35, width: 60, borderRadius: 10 }} type="submit" disabled={!stripe || !elements || loading}>
                {loading ? "...loading" : "Pay"}
            </button>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    );
};

const stripePromise = loadStripe('pk_test_51NvHZDBQrs6daDKj4I5qnHKaLwRquncuZsFWmQxFiO7HHSW2nB3LAc70hrcFtP2WTZXUDWEEY8OpnpNR0uC1f9U400APBtNOSf');


const Stripe = ({ CurrentData, userData }) => {
    const options = {
        mode: 'payment',
        currency: 'usd',
        amount: CurrentData?.price * 100,
    };
    return <Elements stripe={stripePromise} options={options}>
        <CheckoutForm userData={userData} CurrentData={CurrentData} />
    </Elements>
};

export default Stripe;
